import PropTypes from 'prop-types';
import React, { useCallback, useContext } from "react";

import { Button, Typography } from "@material-ui/core";
import {
  ApplicationContext, SectionConsultation,
  SectionContainer,
  SectionHowToGetFinancing,
  SectionLeasingAdvantages,
  SectionLeasingScheme,
} from "@product-site-frontend/shared";
import { graphql } from "gatsby";
import parse from "html-react-parser";

import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import {
  SectionAboutLeasing,
  SectionBusinessSegments,
  SectionLeasingCalculator
} from "../../index";
import PageBox from "../components/PageBox";
import SectionMainPromos from "../components/SectionMainPromos";
import SectionPromotions from "../components/SectionPromotions";
import SEO from '../components/SEO';

SinglePromotion.propTypes = {
  data: PropTypes.object,
};

export default function SinglePromotion({ data }) {
  let {
    aboutLeasingContent,
    businessSegmentsContent,
    financingContent,
    leasingAdvantagesContent,
    leasingSchemeContent,
    promotion,
    promotions
  } = data;

  let mainContent = {
    title: promotion.title,
    description: promotion.description,
    bgDesktop: promotion.bgDesktop,
    titleDealAmount: promotion.titleDealAmount,
    descriptionDealAmount: promotion.descriptionDealAmount,
    showDealAmount: promotion.showDealAmount,
    titleAdvanceRate: promotion.titleAdvanceRate,
    descriptionAdvanceRate: promotion.descriptionAdvanceRate,
    showAdvanceRate: promotion.showAdvanceRate,
    titleTerm: promotion.titleTerm,
    descriptionTerm: promotion.descriptionTerm,
    showTerm: promotion.showTerm,
    showQuiz: promotion.showQuizButton,
    quizButtonText: promotion.quizButtonText,
    quizUrl: promotion.quizUrl
  }

  const { setApplicationOpen } = useContext(ApplicationContext);

  const handleClickOpen = useCallback(
    () => setApplicationOpen(true),
    [setApplicationOpen],
  );

  const ApplicationButton = (
    <Button
      color="primary"
      fullWidth
      onClick={handleClickOpen}
      size="large"
      variant="contained"
    >
      Получить консультацию
    </Button>
  );

  return (
    <PageBox>
      <SEO pageData={promotion?.seo} />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            href: '/promotions',
            text: 'Акции'
          },
          {
            text: promotion?.seo?.title
          }
        ]}
      />
      <SectionMainPromos
        ApplicationButton={ApplicationButton}
        content={mainContent}
      />

      <SectionBusinessSegments
        content={{ ...businessSegmentsContent, ...promotion?.businessSegments }}
      />

      {promotion?.showSectionAboutLeasing && (
        <SectionAboutLeasing
          content={{ ...aboutLeasingContent, ...promotion?.aboutLeasing }}
        />
      )}

      {promotion?.showSectionLeasingScheme && (
        <SectionLeasingScheme content={leasingSchemeContent} />
      )}

      {promotion?.showCalculator && (
        <SectionContainer>
          <SectionLeasingCalculator />
        </SectionContainer>
      )}

      {promotion?.showSectionLeasingAdvantages && (
        <SectionLeasingAdvantages content={leasingAdvantagesContent} />
      )}

      {promotion?.showSectionHowToGetFinancing && (
        <SectionHowToGetFinancing
          ApplicationButton={ApplicationButton}
          content={financingContent}
        />
      )}
      {promotion?.offerConditions && (
        <SectionContainer style={{ paddingTop: 0 }}>
          <Typography color="text.secondary" variant="body2">
            {parse(promotion?.offerConditions)}
          </Typography>
        </SectionContainer>
      )}

      <SectionPromotions content={promotions.nodes} />

      <SectionConsultation />
    </PageBox>
  )
}

export const query = graphql`
  query PromotionQuery($strapiId: Int) {
    promotion: strapiPromotions(strapiId: {eq: $strapiId}) {
      seo {
        title
        description
      }
      title
      description
      bgDesktop {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
      showQuizButton
      quizButtonText
      quizUrl
      titleDealAmount
      descriptionDealAmount
      showDealAmount
      titleAdvanceRate
      descriptionAdvanceRate
      showAdvanceRate
      titleTerm
      descriptionTerm
      showTerm
      offerConditions
      showSectionAboutLeasing
      aboutLeasing {
        title
        description
      }
      businessSegments {
        title
        description
      }
      showSectionLeasingScheme
      showCalculator
      showSectionLeasingAdvantages
      showSectionHowToGetFinancing
    }
    promotions: allStrapiPromotions(sort: { order: DESC, fields: strapiId }) {
      nodes {
        strapiId
        slug
        seo {
          title
        }
        preview {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
      }
    }
    aboutLeasingContent: strapiAboutLeasing {
      title
      description
      cards {
        text
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
    }
    businessSegmentsContent: strapiBusinessSegments {
      title
      description
      cards {
        text
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 512)
            }
          }
        }
      }
    }
    leasingSchemeContent: strapiLeasingScheme {
      title
      caption
      steps {
        title
        description
      }
    }
    leasingAdvantagesContent: strapiLeasingAdvantages {
      cards {
        title
        description
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
      title
    }
    financingContent: strapiFinancing {
      title
      steps {
        title
        description
      }
      bgDesktop {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, placeholder: NONE)
          }
        }
      }
    }
  }
`;